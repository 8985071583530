:root{
  --cor-vermelho1: #E43138;
}
* {
  box-sizing: border-box;
  margin: 0%;
  padding: 0%;
  font-family: 'encode sans expanded', sans-serif;
}
input,
textarea {
  font-size: 16px;
}

@keyframes lds-heart {
  0% {
    transform: scale(0.95);
  }
  5% {
    transform: scale(1.1);
  }
  39% {
    transform: scale(0.85);
  }
  45% {
    transform: scale(1);
  }
  60% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(0.9);
  }
}