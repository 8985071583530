:root {
    --checkmark-fill-color: #7ac142;
    --checkmark-arrow-color: #fff;
    --checkmark-arrow-thickness: 5;
  }
  
  .checkmark {
    display: block;
    margin-left: auto;
    margin-right: auto;
    border-radius: 50%;
    stroke: var(--checkmark-arrow-color);
    stroke-width: var(--checkmark-arrow-thickness);
    stroke-miterlimit: 10;
    box-shadow: inset 0 0 0 var(--checkmark-fill-color);
    animation: fill 0.4s ease-in-out 0.4s forwards, scale 0.3s ease-in-out 0.9s both;
  }
  
  .checkmark__circle {
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: var(--checkmark-arrow-thickness);
    stroke-miterlimit: 10;
    stroke: var(--checkmark-fill-color);
    fill: none;
    animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
  }
  
  .checkmark__check {
    transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
  }
  
  @keyframes stroke {
    100% {
      stroke-dashoffset: 0;
    }
  }
  
  @keyframes scale {
    0%,
    100% {
      transform: none;
    }
    50% {
      transform: scale3d(1.1, 1.1, 1);
    }
  }
  
  @keyframes fill {
    100% {
      box-shadow: inset 0 0 0 100vh var(--checkmark-fill-color);
    }
  }